/***************************************************************************************************
 * alternatelyMerge
 */

declare interface Array<T> {
  alternatelyMerge<T1>(array1: T1[]): (T | T1)[];
  alternatelyMerge<T1, T2>(array1: T1[], array2: T2[]): (T | T1 | T2)[];
  alternatelyMerge<T1, T2, T3>(
    array1: T1[],
    array2: T2[],
    array3: T3[],
  ): (T | T1 | T2 | T3)[];
}

Array.prototype.alternatelyMerge = function (...arrays: any[][]) {
  const _arrays: any[][] = [this, ...arrays];
  const maxLength = _arrays.reduce((carry, arr) => {
    return Math.max(carry, arr.length);
  }, 0);

  const result: any[] = [];

  for (let i = 0; i < maxLength; i++) {
    _arrays.forEach((arr) => {
      if (arr[i] !== undefined && arr[i] !== null) {
        result.push(arr[i]);
      }
    });
  }

  return result;
};

/***************************************************************************************************
 * unique
 */

declare interface Array<T> {
  unique(): T[];
}

Array.prototype.unique = function () {
  return this.filter((v, i, self) => self.indexOf(v) === i);
};

/***************************************************************************************************
 * first / last
 */

declare interface Array<T> {
  first(): T | undefined;
  last(): T | undefined;
}

Array.prototype.first = function () {
  return this[0];
};

Array.prototype.last = function () {
  return this.length > 0 ? this[this.length - 1] : undefined;
};
